<template>
  <div v-if="checkPermission('withdraw', 'action', 'affiliate')">
    <topic-component :topic="$t('withdrawal.withdrawal_insight')" />
    <b-row>
      <b-col cols="6" lg="3">
        <div class="total-card mb-3">
          <div class="label">
            {{ $t('withdrawal.withdrawal_request') }}
          </div>
          <div class="value">
            {{ addCommaAndPrice(summary.pending_total_amount) }}
          </div>
          <div class="badge orange" @click="filterPending">
            {{ summary.pending_total_row }} {{ $t('withdrawal.actions') }}
          </div>
        </div>
      </b-col>
      <b-col cols="6" lg="3">
        <div class="total-card mb-3">
          <div class="label">
            {{ $t('withdrawal.withdrawal_success') }}
          </div>
          <div class="value">
            {{ addCommaAndPrice(summary.success_total_amount) }}
          </div>
          <div class="badge green" @click="filterSuccess">
            {{ summary.success_total_row }} {{ $t('withdrawal.actions') }}
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="6">
        <div class="total-card mb-3" :class="{ 'green-card': !(summary.allow_withdraw < 1000) }">
          <div style="display: flex;">
            <div>
              <div class="label">
                {{ $t('withdrawal.credit_can_withdraw') }}
              </div>
              <div class="value">
                {{ addCommaAndPrice(summary.allow_withdraw) }}
              </div>
              <div class="label flex-center" @click="$bvModal.show('modal_term_condition')" style="cursor: pointer;">
                {{ $t('withdrawal.condition_and_term') }}
                <span :style="iconMaskURL('arrow-caret')"
                  style="display: flex; transform: rotate(270deg); width: 18px; height: 16px" class="caret ml-2"
                  :class="{ 'caret-rotate': visible }"></span>
              </div>
            </div>
            <div class="ml-auto">
              <modalWithdrawal :totalAmount="summary.allow_withdraw" @recallSummary="getData()"></modalWithdrawal>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-card class="mb-3" v-if="checkPermission('withdraw', 'action', 'affiliate')">
      <div class="flex-status-describe">
        <div v-for="status in advanced_report_cards.slice(0, 2)" :key="status.id"
          class="flex-status-describe__content-mb">
          <div class="content-title flex-center" :class="{ 'custom-content-title': checkLocale }">
            <p :class="`${colorStatus(status.background_color)}`">{{ $t(status.title) }}</p>
          </div>
          <div class="content-description "> {{ $t(status.description) }}</div>
        </div>
        <b-collapse v-model="visible" class="flex-status-describe__expand">
          <div v-for="status in advanced_report_cards.slice(2)" :key="status.id" class="flex-status-describe__content-mb">
            <div class="content-title flex-center" :class="{ 'custom-content-title': checkLocale }">
              <p :class="`${colorStatus(status.background_color)}`">{{ $t(status.title) }}</p>
            </div>
            <div class="content-description "> {{ $t(status.description) }}</div>
          </div>
        </b-collapse>
        <button @click="visible = !visible" class="flex-status-describe__toggle btn btn-outline">
          <span>{{ $t('button.showMore') }}</span>
          <span style="width: 18px; height: 16px" :style="iconMaskURL('arrow-caret')" class="caret"
            :class="{ 'caret-rotate': visible }"></span>
        </button>
        <div v-for="status in advanced_report_cards" :key="status.id" class="flex-status-describe__content">
          <div class="content-title flex-center" :class="{ 'custom-content-title': checkLocale }">
            <p :class="`${colorStatus(status.background_color)}`">{{ $t(status.title) }}</p>
          </div>
          <div class="content-description "> {{ $t(status.description) }}</div>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-col lg="4" md="6" sm="12">
          <b-form-group label-cols="12" :label="$t('common.dateTime')" label-for="input-sm">
            <date-time-range-component v-model="time" type="date" />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12">
          <select-filter-component :label-select="$t('report.creditTopUp.list.type')" :options="withdrawalStatus"
            v-model="form.status" />
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient" @click="filterSearch()">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select v-model="form.$limit" :options="pageOptions" size="sm" @change="getSessionReportList" />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll" />
            </div>
          </div>
        </b-col>
        <b-col align="end">
          <b-button variant="primary" class="mr-1" @click="exportAdvancedReport" :disabled="!items.length"
            v-if="checkPermission('withdraw', 'action', 'affiliate')">
            <i class="fas fa-file-excel "></i>
            {{ $t("button.export") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table id="table_withdrawal" responsive class="mb-0" :items="items" :fields="fields" :busy="isBusy" :empty-text="$t('empty')"
          show-empty>
          <template #head()="{ label, field: { key, sortable } }">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty>
            <empty-table />
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(amount)="data">
            {{ addCommaAndPrice(data.item.amount) }}
          </template>
          <template #cell(status)="data">
            <b-badge pill class="px-2 badge-status" :class="`${colorButton(data.item.status)}`">
              {{ filterLangValue(withdrawalStatus, data.item.status) || '-' }}
            </b-badge>
          </template>
          <template #cell(remarks)="data">
            <!-- {{ data.item.remarks && data.item.remarks.length ? data.item.remarks.join(', ') : '-' }} -->
              <div v-for="(remark, index) in data.item.remarks" :key="remark">
                <span v-if="index < 4">
                  - {{ filterValue(approvedRemarkList, remark) }}
                </span>
                <span v-if="data.item.remarks.length > 3 && index == 3">
                  , ...
                </span>
              </div>
          </template>
          <template #cell(action)="data">
            <div class="flex-center">
              <i @click="showModal(data.item._id)" style="color: #7367f0" class="action-button icon-eye mr-2"></i>
              <div
                class="icon-next"
                v-if="data.item.status == 'success'" style="width: 14px; height: 16px; cursor: pointer; background-color:#9F9F9F;"
                @click="showPDF(data.item._id)"
                :style="`${iconMaskURL('file')} ${'background-color: #00927F; cursor: pointer;'}`"
              ></div>
              <i v-if="data.item.status == 'pending'" style="color: #df3f1e" class="action-button icon-trash"
                @click="cancelWithdrawal(data.item)"></i>
            </div>
          </template>
        </b-table>
        <div class="ml-auto">
          <b-pagination v-model="form.$skip" :total-rows="total_rows" :per-page="form.$limit" align="fill" class="my-0"
            size="sm" @input="getSessionReportList"></b-pagination>
        </div>
      </b-row>
    </b-card>
    <b-modal id="modal-export-withdrawal" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5></h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div align="center" v-if="!exportSuccess">
        <b-spinner class="mt-5 mb-1" style="width: 4rem; height: 4rem" label="Large Spinner" variant="warning"
          type="grow">
        </b-spinner>
        <h3 v-html="$t('processing')" class="mb-3"></h3>
      </div>
      <div v-if="exportSuccess">
        <div align="center" v-if="exportSuccess">
          <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2" />
          <h3 v-html="$t('success')" class="mb-3"></h3>
          <div v-for="items in fileExport">
            <a :href="items">{{ items }}</a>
            <hr />
          </div>
        </div>
        <div align="center" v-if="!exportSuccess">
          <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2" />
          <h3 v-html="$t('failed')" class="mb-3"></h3>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal_term_condition" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>{{ $t('withdrawal.condition_and_term_modal') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div>
        <ul>
          <li>{{ $t('withdrawal.condition.li_1') }}</li>
          <li>{{ $t('withdrawal.condition.li_2') }}</li>
          <li>{{ $t('withdrawal.condition.li_3') }}</li>
          <li>{{ $t('withdrawal.condition.li_4') }}</li>
          <li>{{ $t('withdrawal.condition.li_5') }}</li>
          <li>{{ $t('withdrawal.condition.li_6') }}</li>
          <li>{{ $t('withdrawal.condition.li_7') }}</li>
          <li>{{ $t('withdrawal.condition.li_8') }}</li>
        </ul>
      </div>
    </b-modal>
    <b-modal id="modal_pdf_vat" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>{{ $t('account.verify_account.information_and_documents') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div>
        <div v-for="(item, index) in withholding_tax_receipt" :key="item + '_' + index">
          <div class="flex-center mt-2">
            <i v-b-toggle="item + '_file_' + index" style="color: #7367f0" class="action-button icon-eye mr-2"></i>
            <a :href="item" target="_blank" download>{{ item }}</a>
          </div>
          <b-collapse :id="item + '_file_' + index" class="mt-2">
            <iframe :src="item"
             style="width:100%;height:60vh;border-radius: 14px;border: 1px solid #f3f3f3;"
             webkitallowfullscreen
             mozallowfullscreen
             allowfullscreen
             scrolling="auto"/>
          </b-collapse>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal_details" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>{{ $t('withdrawal.withdrawal_details') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="closeModal()"></i>
      </template>
      <div class="">
        <b-row>
          <b-col class="label">
            {{ $t('withdrawal.withdrawal_status') }}
          </b-col>
          <b-col align="end">
            <b-badge pill class="px-2 badge-status" :class="`${colorButton(previewDetails?.status)}`">
              {{ filterLangValue(withdrawalStatus, previewDetails?.status) || '-' }}
            </b-badge>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('messageText.note') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
              <span v-if="!previewDetails?.remarks.length">
                ระบบจะทำการส่ง SMS หรือ E-mail เพื่อแจ้งเตือนการโอนทุกวันที่ 10 ของทุกเดือน
              </span>
              <span v-else>
                <div v-for="remark in previewDetails.remarks" :key="remark">
                  <span>
                    - {{ filterValue(approvedRemarkList, remark) }}
                  </span>
                </div>
              </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.date_withdrawal') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ changeDateFormat(previewDetails?.createdAt) }}
          </b-col>
        </b-row>
        <b-row v-if="previewDetails?.status == 'success'">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.date_transfer_success') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ changeDateFormat(previewDetails?.updatedAt) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              <span v-if="previewDetails?.customer_type == 'personal'">
                {{ $t('account.verify_account.first_name') }}/{{ $t('account.verify_account.last_name') }}
              </span>
              <span v-else>
                {{ $t('account.verify_account.company_name') }}
              </span>
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.name }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('account.verify_account.tax_id') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.tax_id }}
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="previewDetails?.customer_type !== 'personal' && previewDetails?.company_phone">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('account.verify_account.company_phone') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.company_phone }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.phone') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.phone }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.email') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.email }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.address') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ changeFormatAddress(previewDetails?.address) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.bank_method') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.account_name }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.account_number') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.account_no }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.bank') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.bank }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.value_of_withdrawal') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #161E54;">
            {{ addCommaAndPrice(previewDetails?.amount) }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.vat') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #5C698C;">
            +{{ addCommaAndPrice(previewDetails?.vat_7_percent) }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              <!-- if this string has '3' replace with '5' -->
              <span v-if="previewDetails?.customer_type == 'personal'">
                <!-- 5 -->
                {{ $t('subscription.checkout.with_holding_tax').replace('3', '5') }} 
              </span>
              <span v-else>
                {{ $t('subscription.checkout.with_holding_tax') }}
              </span>
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #D30000;">
            <span v-if="previewDetails?.customer_type == 'personal'">
              -{{ addCommaAndPrice(previewDetails?.withholding_tax_5_percent) }}
            </span>
            <span v-else>
              -{{ addCommaAndPrice(previewDetails?.withholding_tax_3_percent) }}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.summary_withdrawal_transfer') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #161E54;">
            {{ addCommaAndPrice(previewDetails?.net_balance) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <div class="label mt-2">{{ $t('withdrawal.summary_withdrawal_result') }}</div>
            <div style="font-size: 36px; font-weight: 700; color: #00927F;">
              {{ addCommaAndPrice(previewDetails?.net_balance) }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <div class="flex-middle mt-3 ml-auto mr-auto">
            <button type="button" class="btn waves-effect waves-light btn-gradient" @click="closeModal()">
              <div style="min-width: 80px;">
                {{ $t('button.close') }}
              </div>
            </button>
          </div>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent.vue";
import SelectFilterComponent from "@/components/common/selectFilterComponent.vue";
import moment from "moment";
import affiliateApi from "@/repository/affiliateApi";
import masterData from '@/common/masterData.json'
import alert from "@/common/alert";

export default {
  name: "Withdrawal",
  components: {
    TopicComponent: () => import("@/components/common/topicComponent"),
    modalWithdrawal: () => import("@/views/affiliate/withdrawal/modalWithdrawalFlow.vue"),
    modalDetails: () => import("@/views/affiliate/withdrawal/modalDetails.vue"),
    TotalListComponent, EmptyTable, SelectFilterComponent, DateTimeRangeComponent
  },
  computed: {
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    }
  },
  data() {
    return {
      visible: false,
      advanced_report_cards: [
        {
          key: 'total_success',
          background_color: 'orange',
          img: 'total-received.svg',
          title: 'withdrawal.status.pending',
          description: 'withdrawal.status.pending_des',
        },
        {
          key: 'total_failed',
          background_color: 'yellow',
          title: 'withdrawal.status.processing',
          description: 'withdrawal.status.processing_des',
        },
        {
          key: 'total_block_spam',
          background_color: 'gray',
          title: 'withdrawal.status.rejected',
          description: 'withdrawal.status.rejected_des'
        },
        {
          key: 'total_destination_error',
          background_color: 'green',
          title: 'withdrawal.status.success',
          description: 'withdrawal.status.success_des'
        },
        {
          key: 'total_blacklist',
          background_color: 'red',
          title: 'withdrawal.status.failed',
          description: 'withdrawal.status.failed_des'
        },
        {
          key: 'hide',
          background_color: 'hide',
          title: 'withdrawal.status.cancelled',
          description: 'withdrawal.status.cancelled_des'
        }
      ],
      totalAll: 0,
      summary: {
        pending_total_row: 0,
        pending_total_amount: 0,
        success_total_row: 0,
        success_total_amount: 0,
        total_amount_all: 0,
        allow_withdraw: 0
      },
      form: {
        date_from: null,
        date_to: null,
        status: null,
        $skip: 1,
        $limit: 10,
      },
      isBusy: false,
      time: [
        new Date(moment(new Date()).format('YYYY-MM-DD')),
        new Date(moment(new Date()).format('YYYY-MM-DD'))
      ],
      fields: [
        {
          key: "createdAt",
          label: "report.sessionReport.date",
          class: 'text-left'
        },
        {
          key: "amount",
          label: "withdrawal.value_of_withdrawal",
          class: 'text-left'
        },
        {
          key: "status",
          label: "report.sessionReport.status",
        },
        {
          key: "remarks",
          label: "account.verify_account.remark",
        },
        {
          key: "action",
          label: "short_link.list.action",
        }
      ],
      items: [],
      total_rows: 1,
      pageOptions: [10, 15, 50, 100],
      withdrawalStatus: masterData.withdrawalStatus,
      exportSuccess: false,
      fileExport: [],
      previewDetails: {
        status: "",
        remarks: [],
        address: {
          address: '',
          sub_district: '',
          district: '',
          province: '',
          zipcode: ''
        },
        amount: 0,
        brand_id: "",
        codeSYS: "",
        customer_bank: {
          account_name: '',
          account_no: '',
          bank: '',
          book_bank: ''
        },
        customer_type: "",
        customer_id: "",
        email: "",
        group_id: "",
        member_id: "",
        name: "",
        net_balance: 0,
        phone: "",
        tax_id: "",
        transaction_period: "",
        vat_7_percent: 0,
        withdraw_date: "",
        withholding_tax_3_percent: 0
      },
      withholding_tax_receipt: [],
      approvedRemarkList: masterData.approvedRemarkList
    }
  },
  mounted() {
    setTimeout(() => {
      this.getData()
    }, 0)
    if (!this.withdrawalStatus.find(item => item.value === 'pending,processing')) {
      this.withdrawalStatus.push({
        value: "pending,processing",
        en: "Pending & Processing",
        th: "รออนุมัติ & กำลังดำเนินการ",
        cn: "待完成 & 进行中",
        id: "Tertunda & Sedang Diproses",
        my: "Tertunda & Sedang Diproses",
      })
    }
  },
  methods: {
    showPDF(id) {
      this.clearPreviewData();
      this.withholding_tax_receipt = []
      affiliateApi.getRequestDetails(id).then((res) => {
        setTimeout(() => {
          if (!res.withholding_tax_receipt || res.withholding_tax_receipt.length === 0) {
            alert.fail(this, '')
          } else {
            this.withholding_tax_receipt = res.withholding_tax_receipt
            this.$bvModal.show('modal_pdf_vat')
          }
        }, 30);
      }).catch((err) => {
        alert.fail(this, err.message)
      })
    },
    getData() {
      this.getSessionReportList()
      this.getSummaryList()
    },
    changeFormatAddress(data) {
      const address = data?.address ? data?.address : ''
      const sub_district = data?.sub_district ? 'แขวง' + data?.sub_district : ''
      const district = data?.district ? 'เขต' + data?.district : ''
      const province = data?.province ? data?.province : ''
      const zipcode = data?.zipcode ? data?.zipcode : ''
      return address + ' ' + sub_district + ' ' + district + ' ' + province + ' ' + zipcode
    },
    closeModal() {
      this.$bvModal.hide('modal_details');
    },
    clearPreviewData() {
      this.previewDetails = {
        status: "",
        remarks: [],
        address: {
          address: '',
          sub_district: '',
          district: '',
          province: '',
          zipcode: ''
        },
        amount: 0,
        brand_id: "",
        codeSYS: "",
        customer_bank: {
          account_name: '',
          account_no: '',
          bank: '',
          book_bank: ''
        },
        customer_type: "",
        customer_id: "",
        email: "",
        group_id: "",
        member_id: "",
        name: "",
        net_balance: 0,
        phone: "",
        tax_id: "",
        transaction_period: "",
        vat_7_percent: 0,
        withdraw_date: "",
        withholding_tax_3_percent: 0
      }
    },
    showModal(id) {
      this.clearPreviewData();
      affiliateApi.getRequestDetails(id).then((res) => {
        setTimeout(() => {
          this.previewDetails = res
          this.$bvModal.show('modal_details');
        }, 30);
      }).catch((err) => {
        alert.resendFailed(this, err.message)
      })
    },
    getSessionReportList(noDate) {
      this.isBusy = true
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + "T00:00:00"
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + "T23:59:59"
      const data = _.cloneDeep(this.form)
      if (noDate) {
        delete data.date_from
        delete data.date_to
      }
      affiliateApi.withdrawList(_.cloneDeep(data)).then(resp => {
        if (resp) {
          this.items = resp?.data
          this.total_rows = resp?.total
          this.totalAll = resp?.total
        }
      }).finally(() => {
        this.isBusy = false
      })
    },
    getSummaryList() {
      this.isBusy = true
      affiliateApi.getSummary(this.userInfo._id).then(resp => {
        if (resp) {
          this.summary = {
            pending_total_row: resp?.withdraw_pending.total_row,
            pending_total_amount: resp?.withdraw_pending.total_amount,
            success_total_row: resp?.withdraw_success.total_row,
            success_total_amount: resp?.withdraw_success.total_amount,
            total_amount_all: resp?.total_amount_all,
            allow_withdraw: resp?.allow_withdraw
          }
        }
      }).finally(() => {
        this.isBusy = false
      })
    },
    scrollToHighLight() {
      const target = document.querySelector('#table_withdrawal')
      if (!_.isNull(target)) {
        target.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
      }
    },
    filterPending() {
      this.form.status = 'pending,processing';
      this.form.$skip = 1;
      this.getSessionReportList(true);
      this.scrollToHighLight();
    },
    filterSuccess() {
      this.form.status = 'success';
      this.form.$skip = 1;
      this.getSessionReportList(true);
      this.scrollToHighLight();
    },
    filterSearch() {
      this.form.$skip = 1
      this.getSessionReportList()
    },
    filterValue(arr, val) {
      return functions.filterMasterData(arr, val, this.$i18n.locale)
    },
    filterLangValue(arr, val) {
      return functions.filterMasterData(arr, val, this.$i18n.locale)
    },
    addCommaAndPrice(data) {
      return `฿${data?.toLocaleString() || 0}`
    },
    changeDateFormat(date) {
      return functions.changeDateFormat(date)
    },
    changeDeviceName(data) {
      return _.get(data, 'device', '')
    },
    changeModelName(data) {
      let brand = _.get(data, 'brand', '')
      let model = data?.model ? `: ${_.get(data, 'model', '')}` : ''
      return brand + ' ' + model
    },
    colorButton(status) {
      switch (status) {
        case 'pending':
          return 'orange';
        case 'processing':
          return 'yellow'
        case 'rejected':
          return 'gray'
        case 'success':
          return 'green'
        case 'failed':
          return 'red'
        case 'cancelled':
          return 'hide'
        default:
          return null

      }
    },
    exportAdvancedReport() {
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T00:00:00';
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T23:59:59';
      const form = {
        file_type: "csv",
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        status: this.form.status,
      }
      affiliateApi.exportWithdrawList(form).then(response => {
        if (response?.codeSYS === '001') {
          this.fileExport = response?.url_files
          this.exportSuccess = true
        } else {
          this.exportSuccess = false
        }
        this.$bvModal.show('modal-export-withdrawal')
      }).catch(err => {
        this.$bvModal.hide('modal-export-withdrawal')
        alert.findFailed(this, err.response.data.message)
      })
    },
    cancelWithdrawal(item) {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('withdrawal.confirm_for_cancel_withdrawal'),
        text: this.$t('withdrawal.can_not_go_back'),
        showCancelButton: true,
        confirmButtonColor: '#19CBAC',
        cancelButtonColor: '#ccc',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
        allowOutsideClick: false,
        width: '40em',
        padding: '1.25rem'
      }).then((result) => {
        if (result.isConfirmed) {
          affiliateApi.cancelWithdrawRequest(item._id).then(resp => {
            if (resp) {
              alert.deleteSuccess(this)
              setTimeout( () => {
                this.getData()
              }, 0)
            }
          })
        }
      })
    },
    resetSearch() {
      this.form = {
        date_from: null,
        date_to: null,
        $skip: 1,
        $limit: 10,
      }
      this.time = [
        new Date(moment(new Date()).format("YYYY-MM-DD") + "T00:00:00"),
        new Date(moment(new Date()).format("YYYY-MM-DD") + "T23:59:59"),
      ]
      this.getSessionReportList()
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    colorStatus(bg) {
      const background = {
        orange: 'bg-status-orange',
        yellow: 'bg-status-yellow',
        gray: 'bg-status-gray',
        green: 'bg-status-green',
        red: 'bg-status-red',
        hide: 'bg-status-hide',
      }
      return background[bg]
    },
    iconMaskURL(name) {
      return functions.iconMaskURL(name)
    },
    checkLocale() {
      return this.$i18n.locale === "th" || this.$i18n.locale === "en"
    },
  },
}
</script>

<style lang="scss" scoped>
$bg-status-orange: #FE7A00;
$bg-text-orange: #fff;
$bg-status-yellow: #FFF7AF;
$bg-text-yellow: #E3B100;
$bg-status-gray: #E8E8E8;
$bg-text-gray: #555555;
$bg-status-green: #DAFFF2;
$bg-text-green: #00927F;
$bg-status-red: #FFDADA;
$bg-text-red: #D60000;
$bg-status-hide: #E8E8E8;
$bg-text-hide: #9F9F9F;

.badge-status {
  &.orange {
    background-color: $bg-status-orange;
    color: $bg-text-orange;
  }

  &.yellow {
    background-color: $bg-status-yellow;
    color: $bg-text-yellow;
  }

  &.gray {
    background-color: $bg-status-gray;
    color: $bg-text-gray;
  }

  &.green {
    background-color: $bg-status-green;
    color: $bg-text-green;
  }

  &.red {
    background-color: $bg-status-red;
    color: $bg-text-red;
  }

  &.hide {
    background-color: $bg-status-hide;
    color: $bg-text-hide;
  }
}

.total-card {
  background-color: #FFFFFF;
  border: 1px solid #DDEAF8;
  padding: 32px 24px 24px;
  border-radius: 10px;
  height: 164px;

  .label {
    font-size: 14px;
    color: #5C698C;
  }

  .value {
    font-size: 32px;
    color: #5C698C;
    font-weight: 700;
  }

  .badge {
    min-width: 100px;
    padding: 12px 24px;
    border-radius: 30px;
    cursor: pointer;
    &:hover {
      filter: brightness(90%);
    }

    &.orange {
      background-color: #FFECDA;
      color: #FF8551;
    }

    &.green {
      background-color: #DAFFF2;
      color: #00927F;
    }
  }

  &.green-card {
    background-color: #234F43;
    border: 1px solid #234F43;

    .value,
    .label {
      color: #FFFFFF;
    }
  }
}

.flex-status-describe {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 20px;
  background: #FFF;
  border-radius: 10px;

  .flex-status-describe__content,
  .flex-status-describe__content-mb {
    display: flex;
    flex-direction: row;
    gap: 42px;

    div.content-description {
      color: #000;
      font-size: 14px;
      font-weight: 600;
    }

    div.content-title,
    .custom-content-title {
      width: 220px;

      p {
        white-space: nowrap;
        width: auto;
        padding: 4px 12px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 500;
        margin: 0px;
      }
    }

    .custom-content-title {
      width: 160px !important;
    }

    @media (max-width: 768px) {
      gap: 5px;

      div.content-description {
        width: 100%;
        justify-content: flex-start;
        display: flex;
      }

      // div.content-title,
      // .custom-content-title {
      //   width: 275px !important;
      // }
    }

    @media (max-width: 576px) {
      display: none;
      flex-direction: column;
      gap: 12px;
    }

    .bg-status-orange {
      border: 1px solid $bg-status-orange;
      background: $bg-status-orange;
      color: $bg-text-orange;
    }

    .bg-status-yellow {
      border: 1px solid $bg-status-yellow;
      background: $bg-status-yellow;
      color: $bg-text-yellow;
    }

    .bg-status-gray {
      border: 1px solid $bg-status-gray;
      background: $bg-status-gray;
      color: $bg-text-gray;
    }

    .bg-status-green {
      border: 1px solid $bg-status-green;
      background: $bg-status-green;
      color: $bg-text-green;
    }

    .bg-status-red {
      border: 1px solid $bg-status-red;
      background: $bg-status-red;
      color: $bg-text-red;
    }

    .bg-status-hide {
      border: 1px solid $bg-status-hide;
      background: $bg-status-hide;
      color: $bg-text-hide;
    }
  }

  .flex-status-describe__content {
    display: grid;
    grid-template-columns: 1fr 8fr;
    gap: 24px;

    @media (max-width: 576px) {
      display: none;
      flex-direction: column;
      gap: 12px;
    }
  }

  .flex-status-describe__expand {
    display: none;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }
  }

  .flex-status-describe__toggle {
    display: none;

    @media (max-width: 576px) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
  }

  .flex-status-describe__content-mb {
    display: none;

    @media (max-width: 576px) {
      display: flex;
    }
  }
}

.line-dotted {
  height: 0;
  width: 100%;
  border-top: 1px dotted #C5D1DE;
}

.label {
  font-size: 14px;
  color: #5C698C;
  font-weight: 700;

}
</style>

<style lang="scss">
#modal_details,
#modal_term_condition {
  h5 {
    margin-bottom: 0;
  }

  .modal-header {
    padding-bottom: 0.5rem;
  }
}
#modal_pdf_vat,
#modal_details {
  .modal-header {
    border-bottom: 1px solid #E9ECEF;
  }
}
</style>